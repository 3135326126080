import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../button/Button';
import { Link } from 'react-router-dom';
import img from '../../assets/images/background/line.png';

ProjectInfo.propTypes = {
    data: PropTypes.array.isRequired,
    tags: PropTypes.array,
    heading: PropTypes.string,
    subheading: PropTypes.string,
    desc: PropTypes.string,
    showButton: PropTypes.bool
};

ProjectInfo.defaultProps = {
    heading: 'test',
    subheading: '',
    showButton: false,
    desc:'',
    tags:[]
};
function ProjectInfo(props) {
    const { data,tags, heading, subheading, showButton, desc } = props;
    return (
        <section className="roadmap">
            <img src={img} alt="" className="img-line" />
            <div className="shape"></div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text center">
                            <h6 className="sub-heading"><span>{subheading}</span></h6>
                            <h3 className="heading pd">{heading}</h3>
                            {desc && <div dangerouslySetInnerHTML={{ __html: desc }}/> }
                        </div>
                        <div className="line"></div>
                        {tags.length > 0 &&  <div className="block-text center">
                         Tags:
                            {tags.map((tag, index) => (
                                <small key={index}>
                                    <Link to={`/projects?tag=${tag}`} className="sub-heading" style={{ marginInline: "5px", fontSize: "10px" }}>{tag}</Link>
                                </small>
                            ))}
                        </div>}
                        <div className={data.length % 2 === 0 ? "roadmap__main roadmap__even_end" : "roadmap__main"} data-aos="fade-up" data-aos-duration="2000">
                            {data.map((idx,index)=> (
                                <div key={index} className={`roadmap-box ${idx.class}`}>
                                    <div className="content">
                                        <h5 className="title">{idx.title}</h5>
                                        <div className="text" dangerouslySetInnerHTML={{ __html: idx.desc }} />
                                    </div>
                                    {idx.contactButton && <Link to="/contact" className="action-btn"><span>Contact Us</span></Link>}
                                </div>
                            ))}
                            <div className="icon"></div>
                            {/* {data.length % 2 !== 0 & <div className="icon bottom"></div>} */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProjectInfo;
